import React, { useEffect, useState } from "react";
import GameModule from "./game";
import ScoreModule from "./score";
import { useCookies } from 'react-cookie';
import { CheckAnswer, GetSongs } from "../../../functions/fnc_singleplayer";
import Result from "./result";


function SinglePlayer() {
    const [cookies, setCookie] = useCookies(['song-quiz']);
    const [data, setData] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [songs, setSongs] = useState([0]);
    const [score, setScore] = useState(0);
    const [highscore, setHighscore] = useState(0);
    const [seen, setSeen] = useState(false);
    const [vol, setVol] = useState(20);




    useEffect(() => {
        if (cookies.SQH) { setHighscore(cookies.SQH) }
        GetSongs(songs).then((data) => {
            setData(data)
        })

        if (highscore < score) {
            setHighscore(score)
            const date = new Date();
            date.setFullYear(date.getFullYear() + 100);
            setCookie('SQH', score, { path: '/', expires: date });
        }

        return () => {

        }
        // eslint-disable-next-line
    }, [score, setCookie])


    //Audio Player Part
    const broke = async () => {
        const aPlayer = document.getElementById('media');
        aPlayer.pause();
        aPlayer.currentTime = 0;
    }

    const start = async () => {
        const aPlayer = document.getElementById('media');
        aPlayer.volume = 0.2;
        aPlayer.play();
    }

    function setVolume() {
        var val = vol / 100;
        document.getElementById('media').volume = val;
    }


    const changeVolume = () => {
        return ({ target: { value } }) => {
            let val = value / 100
            document.getElementById('media').volume = val;
            //console.log(value)
            setVol(value)
        }
    };


    //click fnc 
    function click(name) {
        setSongs(songs => [...songs, data.answer.id])
        broke();
        const values = { id: data.answer.id, select: name }
        CheckAnswer(values)
            .then(p_data => {
                setAnswer(p_data)
                setSeen(true);
                if (p_data.right) {
                    setScore(score + 1);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    //keyHandler
    

    //next fnc
    function next() {
        if (answer.right) {
            setData(null)
            GetSongs(songs).then((data) => {
                setData(data);
                setSeen(false)
            })
        } else {
            setData(null)
            setScore(0);
            setSongs([0]);
            GetSongs(songs).then((data) => {
                setData(data);
                setSeen(false)
            })
        }
    }

    function GameModul() {

        if (data === null) return (<div>LOAD</div>)
        if (!seen) return <GameModule data={data} start={start} click={click} setVol={setVolume} vol={vol} />
        if (seen) return <Result next={next} data={answer} />

    }

    return (<div>
        <div className="card card-dark color-darkblue text-white middle w-75  mx-auto">
            <div className="card-body container">
                <ScoreModule score={score} hscore={highscore} changeVol={changeVolume} />
                <GameModul />
            </div>
        </div>

    </div>)

}


export default SinglePlayer;