import React from "react";

function Result(props) {


    //answer txt fnc
    function fnc_answertxt(data) {
        switch (data) {
            case true:
                return ("Richtig")
            case false:
                return ("Falsch")

            default:
                break;
        }
    }

    function btn_ergebnis(data) {
        if (data) return (<div> <div onClick={() => props.next()} className="text-center mx-auto color-blue  btn w-lg-25 w-xl-25 w-md-25 w-sm-50 text-white">Nächster Song</div> <br /></div>)
        if (!data) return (<div> <div onClick={() => props.next()} className="text-center mx-auto color-blue  btn w-lg-25 w-xl-25 w-md-25 w-sm-50 text-white">Neue Runde</div> <br /></div>)

    }


    return (<div >
        <div className="card w-75 mx-auto color-lightblue mt-5 mb-5" >
            <div className="card-body text-center">
                <h5 className="text-center p-2">Ergebnis</h5>
                <div className="text-center">Deine Antwort war {fnc_answertxt(props.data.right)}</div>
                <div className="text-center">Der Song hieß <u>{props.data.r_name}</u></div>
                <div className="text-center">Der Song ist von <u>{props.data.r_artist}</u></div><br />
                {btn_ergebnis(props.data.right)}
            </div>
        </div>
    </div>)

}


export default Result;