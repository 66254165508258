
function Proposals() {

    return (<div>
        <div className="card card-dark color-darkblue text-white w-50 middle ">
            <div className="card-body text-center ">
                <h2 className="card-title ">Vorschläge</h2>
                <hr className="bg-white mb-4" />
                <div className="t-sm text-left">
                    <div className="w-75 col-centered">
                        <input
                            className="input-SQ rounded form-control row mt-3  mx-auto"
                            type="text"
                            name="name"
                            placeholder="Kurzfassung"
                        />

                        <select className="input-SQ rounded form-control  row mt-3  mx-auto" name="mode">
                            <option selected value="bug">Fehler(Bug)</option>
                            <option value="song">Neuer Song</option>
                            <option value="text">Rechtschreibung</option>
                            <option value="other">Anderes</option>
                        </select>

                        <textarea
                            className="input-SQ rounded form-control  row mt-3  mx-auto"
                            type="textarea"
                            name="desc"
                            cols="40"
                            rows="5"
                            placeholder="Schilder dein Anliegen"

                        />


                        <br />
                        
                        <button className="btn text-white color-lightblue" >Vorschlagen</button>


                    </div>
                </div>
            </div>
        </div>
    </div>)

};

export default Proposals;