import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Changelog from "./pages/other/changelog";
import Home from "./pages/home";
import Job from "./pages/job";
import Impressum from "./pages/other/impressum";
import SinglePlayer from "./pages/game/singleplayer";
import Proposals from "./pages/other/proposals";



function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Home />
          }}
        />

        <Route
          exact
          path="/Changelog"
          render={() => {
            return <Changelog />
          }}
        />

        <Route
          exact
          path="/Jobs"
          render={() => {
            return <Job />
          }}
        />

        <Route
          exact
          path="/Impressum"
          render={() => {
            return <Impressum />
          }}
        />

        <Route
          exact
          path="/Proposals"
          render={() => {
            return <Proposals />
          }}
        />

        <Route
          exact
          path="/Singleplayer"
          render={() => {
            return <SinglePlayer />
          }}
        />

      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
