import React, { useState } from "react";


function GameModule(props) {
    const [info, setInfo] = useState('Klick mich um zu Starten!');

    setTimeout(() => {
        setInfo('Wie heißt der Song?')
    }, 3000);

    return (<div>
        <audio id="media" autoPlay={true} onLoadedData={props.setVol} src={props.data.answer.url}></audio>
        <div className="row mt-5">
            <div onClick={() => { props.start() }} data-toggle="tooltip" data-placement="top" title="Klick mich um den Song zuhören!" className="clicker mb-5 w-md-25 w-lg-25 w-xl-25 w-sm-50 mx-auto text-white mt-4 text-center btn">
                {info}
            </div>
        </div>
        <div className="row justify-content-around">
            <div onClick={() => props.click(props.data.scope[0].name)} className="col-lg-4 col-md-4 col-xs-2 mb-5  w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-white d-flex btn btn-large">
                <div>
                    <span className="badge color-darkblue mr-3 game">1</span>
                    {props.data.scope[0].name}
                </div>
            </div>
            <div onClick={() => props.click(props.data.scope[1].name)} className="col-lg-4 col-md-4 offset-md-1 col-xs-2  mb-5 col-xs w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-center text-white d-flex btn btn-large">
                <div>
                    <span className="badge color-darkblue mr-3 game">2</span>
                    {props.data.scope[1].name}
                </div>
            </div>
            <div onClick={() => props.click(props.data.scope[2].name)} className="col-lg-4 col-md-4 col-xs-2 mb-5  w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue  text-white  clicker d-flex btn btn-large">
                <div>
                    <span className="badge color-darkblue mr-3 game">3</span>
                    {props.data.scope[2].name}
                </div>
            </div>
            <div onClick={() => props.click(props.data.scope[3].name)} className="col-lg-4 col-md-4 offset-md-1 col-xs-2  mb-5 col-xs w-md-25 w-lg-25 w-xl-25 w-sm-75 color-lightblue text-center text-white d-flex btn btn-large">
                <div>
                    <span className="badge color-darkblue mr-3 game">4</span>
                    {props.data.scope[3].name}
                </div>
            </div>
        </div>
    </div>)
}

export default GameModule;