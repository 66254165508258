import React from "react";
import { Link } from "react-router-dom";


function Home() {

    return (<div>
        <div className="container middle ">
            <div className="row ">
                <div className="col-md-4 col-sm-7 d-flex text-white">
                    <div>
                        <h1>SongQuiz</h1>
                        <hr className="bg-white"/>
                        <p className="text-muted">hey ho,
                            in der letzten Zeit hat sich viel getan.
                            Dies hat dazu geführt das wir aus der Alpha in Beta gekommen sind.<br />
                            Mfg SongQuiz</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-7"></div>
                <div className="col-md-4 col-sm-7 d-flex">
                    <div className="card card-dark color-darkblue text-white w-100 h-100">
                        <div className="card-body text-center ">
                            <h5 className="card-title">Spielen?</h5>
                            <hr className="bg-white mb-4" />
                            <div className="row">
                                <Link  to="Singleplayer" className="col btn color-blue text-white">Einzelspieler</Link>
                                <div className="col-auto"></div>
                                <div className="col btn disabled  color-blue text-white">Soon...</div>
                            </div>
                        </div>
                        <p className="text-center text-muted">Stürze dich jetzt ins Abenteuer!</p>
                    </div>
                </div>
            </div>
        </div>
    </div >)
}

export default Home;