import React from "react";


function ScoreModule(props) {

    return (
        <div className="row justify-content-center mt-5 text-white mx-auto " >
            <div className="col-md-4 text-center">
                <div className="card color-lightblue w-100 d-flex">
                    <div><span className="small ">HighScore: </span> <strong className="badge color-darkblue score">{props.hscore}</strong></div>
                </div>
            </div>
            <div className="col-md-4 text-center w-100">
                
            </div>
            <div className="col-md-4 text-center ">
                <div className="card color-lightblue w-100 d-flex">
                    <div><span className="small ">Score: </span> <strong className="badge color-darkblue score">{props.score}</strong></div>
                </div>
            </div>
        </div>)
}

export default ScoreModule;