import fetchURL from "../Config.js"




//Get Songs
const GetSongs = (songs) =>
    fetch(`${fetchURL}/game/select/scopes`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
            "usedIds": songs
        })
    })
        .then(res =>
            res.json()
        )

//check Answer
const CheckAnswer = (values) =>
    fetch(`${fetchURL}/game/check/answer`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
    })
        .then(res => res.json())





export { GetSongs, CheckAnswer }