import React from "react";
import { Link } from "react-router-dom";

function Navbar() {

    return (<div>
        <div className="navbar navbar-dark navbar-static-top color-darkblue  mx-auto w-75 mt-3 ">
            <Link to="/" className="navbar-brand text-white ">SongQuiz</Link>
            <div className="navbar w-50">
                <Link to="/Changelog" className="nav-item text-white">Changelog</Link>
                {/* <Link to="/Proposals" className="nav-item text-white">Vorschläge</Link> */}
                <a href="https://wiki.song-quiz.de" target="_blank" rel="noreferrer" className="nav-item text-white">Wiki</a>
                <a href="https://discord.song-quiz.de" target="_blank" rel="noreferrer" className="nav-item text-white">Discord</a>
            </div>
        </div>
    </div>)

}

export default Navbar;