import React, { useEffect, useState } from "react";
import fetchURL from "../../Config";
import {RiShieldCheckFill} from "react-icons/ri";

function ApplicationForm(props) {
    const [data, setData] = useState();
    const [finish, setFinish] = useState(false);
    const [values, setValues] = useState({ name: '', mail: '', content: '' });



    useEffect(() => {
        setData(props.data)
    }, [props])

    if (!data) return (<div></div>)

    //set data 
    const set = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }));
            console.log(value)
        }
    };


    const submitButton = async () => {
        const sub_data = { jobid: data.id, name: values.name, mail: values.mail, content: values.content };
        //check if data empty
        if (sub_data.jobid.length !== 0 || sub_data.name.length !== 0 || sub_data.mail.length !== 0 || sub_data.content.length !== 0) {

            fetch(`${fetchURL}/job/application`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sub_data),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    setFinish(true);
                    setValues();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            console.log(data);
        } else {
            //console.log("NEIN")
            alert("Fülle bitte alle Felder aus!")
        }
    }

    const Form = ({dataa}) => {
        if (!finish) {
            return (<div>
                <div className="modal-header">
                    <h5 className="modal-title" id="jobApplicationModualLabel">Bewerbung als {dataa.title}</h5>
                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body container text-center">
                    <input
                        className="input-SQ rounded form-control row mt-3  mx-auto"
                        type="text"
                        name="name"
                        onChange={set('name')}
                        placeholder="Name"
                        value={values.name}
                    />

                    <input
                        className="input-SQ rounded form-control row mt-3  mx-auto"
                        type="mail"
                        name="mail"
                        onChange={set('mail')}
                        placeholder="E-mail"
                        value={values.mail}
                    />

                    <textarea
                        className="input-SQ rounded form-control row mt-3  mx-auto"
                        type="textarea"
                        name="bew"
                        cols="40"
                        rows="5"
                        onChange={set('content')}
                        placeholder="Bewerbung"
                        value={values.content}
                    />

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn text-white color-blue" data-dismiss="modal">Schließen</button>
                    <button type="button" onClick={() => submitButton()} className="btn text-white color-lightblue">Absenden</button>
                </div>
            </div>)
        } else {
            return (<div>
                <div className="modal-header">
                    <h5 className="modal-title" id="jobApplicationModualLabel">Danke für deine Bewerung!</h5>
                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body container text-center">
                <h6>Wir werden uns bei dir Melden!</h6>
                <RiShieldCheckFill className="icon text-white" />
                <p className="mt-2 text-muted">Dein SongQuiz Team</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn text-white color-blue" data-dismiss="modal">Schließen</button>
                </div>
            </div>)
        }
    }

    return (<div style={{ marginTop: '5rem' }}>
        <button type="button" className="btn color-blue text-white" data-toggle="modal" data-target={`#jobApplicationModual${data.id}`}>
            Bewirb dich!
        </button>


        <div className="modal fade blur" id={`jobApplicationModual${data.id}`} tabIndex="-1" role="dialog" aria-labelledby="#jobApplicationModualLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content color-darkblue">
                    {Form({dataa: data})}
                </div>
            </div>
        </div>
    </div>)

}

export default ApplicationForm;